import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import SuccessTransferModal from "./SuccessTransferModal";
import { useForm, Controller } from "react-hook-form";
import { Context } from "../../../../utils/context";
import classNames from "classnames";
import Select from "react-select";
import SuccessAssignModal from "./SuccessAssignModel";
const AssignEngineerModel = (props) => {
  const [modalTransfer, setModalTransfer] = useState(false);

  const { getData, postData, Select2Data } = useContext(Context);

  const [complaint_status, setcomplaint_status] = useState([]);
  const [service_engineer, setservice_engineer] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm();

  useEffect(() => {
    reset({ ids: props?.ids });
  }, [props?.ids]);

  const [minScheduleDate, setMinScheduleDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });

  const [scheduleDate, setScheduleDate] = useState("");

  const handleScheduleDateChange = (event) => {
    setScheduleDate(event.target?.value);
  };

  const onSubmit = async (data) => {
    try {
      console.log(data);

      const response = await postData(
        `/complaint-management/complaints/assign-engineer`,
        data
      );
      // console.log(response);
      if (response?.success) {
        await setModalTransfer(response?.success);
      }
      reset();
      setTimeout(() => {
        setModalTransfer(0);
        props.onHide();
        props.getAllData();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllData = async () => {
    {
      const res = await getData(`/masters/all-complaint-status`);
      setcomplaint_status(await Select2Data(res.data, "complaint_status_id"));
    }
    {
      const res = await getData(`/masters/all-service-engineers`);
      setservice_engineer(res.data);
    }
  };

  useEffect(() => {
    getAllData();
    reset();
  }, [props.show]);

  return (
    <>
      <Modal
        {...props}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main-class-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h6 className="mt-2">Assign Engineer</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
            <Row>
              <Col sm={12} md={12} lg={6}>
                <Form.Group className="mb-3" controlId="formGroupEmail">
                  <Form.Label>Assign to</Form.Label>
                  <Controller
                    name="service_engineer_id" // name of the field
                    {...register("service_engineer_id", {
                      required: "service engineer is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        isDisabled={props.view}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.service_engineer_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={service_engineer}
                      />
                    )}
                  />
                </Form.Group>
              </Col>

              <Col xs={6}>
                <Form.Label>Schedule Date</Form.Label>
                <Form.Control
                  required
                  type="date"
                  min={minScheduleDate}
                  onChange={handleScheduleDateChange}
                  className={errors.schedule_date ? "is-invalid" : ""}
                  {...register("schedule_date", {
                    required: true,
                  })}
                />
                {errors.schedule_date && (
                  <Form.Control.Feedback type="invalid">
                    {errors.schedule_date.message}
                  </Form.Control.Feedback>
                )}
              </Col>

              <Col xs={6}>
                <Form.Label>Schedule Time</Form.Label>
                <Form.Control
                  required
                  type="time"
                  className={errors.schedule_time ? "is-invalid" : ""}
                  {...register("schedule_time", {
                    required: true,
                  })}
                />
                {errors.schedule_time && (
                  <Form.Control.Feedback type="invalid">
                    {errors.schedule_time.message}
                  </Form.Control.Feedback>
                )}
              </Col>

              <Col xs={6}>
                <Form.Label>Completion Date</Form.Label>
                <Form.Control
                  required
                  type="date"
                  min={minScheduleDate}
                  onChange={handleScheduleDateChange}
                  className={errors.completion_time ? "is-invalid" : ""}
                  {...register("completion_time", {
                    required: true,
                  })}
                />
                {errors.completion_time && (
                  <Form.Control.Feedback type="invalid">
                    {errors.completion_time.message}
                  </Form.Control.Feedback>
                )}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="dark"
            // onClick={() => {
            //   setModalTransfer(true);
            //   props.onHide();
            // }}
            type="button"
            onClick={handleSubmit(onSubmit)}
          >
            Assign
          </Button>
        </Modal.Footer>
      </Modal>
      <SuccessAssignModal
        show={modalTransfer}
        onHide={() => setModalTransfer(false)}
      />
    </>
  );
};

export default AssignEngineerModel;
