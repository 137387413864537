import React, { useState, handlelick, active, useContext } from "react";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Context } from "../../../utils/context";
library.add(fas);

const Sidebar = () => {
  const { isAllow } = useContext(Context);
  const [isOpen, setIsOpen] = useState(true);

  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  }, [location.pathname]);

  const [active, setActive] = useState("0");

  const [activeAccordionItem, setActiveAccordionItem] = useState(null);
  const [activeInnerListItem, setActiveInnerListItem] = useState(null);

  const handleAccordionClick = (itemKey) => {
    if (active == itemKey) {
      setActive(null);
    } else {
      setActiveAccordionItem(itemKey === activeAccordionItem ? null : itemKey);
      setIsOpen(true);
    }
  };

  const handleInnerListItemClick = (itemId) => {
    setActiveInnerListItem(itemId === activeInnerListItem ? null : itemId);
  };

  return (
    <>
      <section style={{ width: isOpen ? "350px" : "90px" }} className="sidebar">
        <div className="top_section">
          {isOpen && (
            <Link to="/advanceDashboard" className="text-decoration-none">
              <h3 className="Itlogtitle">IT Pulse</h3>
            </Link>
          )}
          <div
            style={{ marginLeft: isOpen ? "30px" : "22px" }}
            className="bars"
          >
            <FaBars onClick={() => setIsOpen(!isOpen)} />
          </div>
        </div>

        <ul className="list-unstyled ps-0">
          <Accordion defaultActiveKey={active}>
            <Accordion.Item
              eventKey="1"
              className={`mb-1 ${activeAccordionItem === "1" ? "active" : ""}`}
              onClick={() => handleAccordionClick("1")}
            >
              <Accordion.Header>
                <div className="text-clr me-2">
                  <FontAwesomeIcon
                    icon="fa-solid fa-house"
                    onClick={() => setIsOpen(!isOpen)}
                  />
                  <b></b>
                  <b></b>
                </div>
                <p
                  onClick={() => setIsOpen(!isOpen)}
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  Dashboard
                </p>
              </Accordion.Header>
              <Accordion.Body style={{ display: isOpen ? "block" : "none" }}>
                <ul className=" list-unstyled fw-normal pb-1 small">
                  {/* <Link
                    to="/privateDashboard"
                    className="text-decoration-none "
                  >
                    <li
                      className={`${isOpen
                        ? headerText.includes("/privateDashboard")
                          ? "active block"
                          : "none"
                        : "none"
                        }`}
                      onClick={() =>
                        handleInnerListItemClick("privateDashboard")
                      }
                    >
                      <p
                        onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Private Dashboard
                      </p>
                    </li>
                  </Link> */}
                  <Link to="/advanceDashboard" className="text-decoration-none">
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/advanceDashboard")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("advanceDashboard")
                      }
                    >
                      <p
                        onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Advance Dashboard
                      </p>
                    </li>
                  </Link>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            {/* ****************************************************************Settings ******************************************* */}

            {[
              9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
              26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
              42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
            ].some((value) => isAllow.includes(value)) ? (
              <Accordion.Item
                // eventKey="2"
                // className="mb-1"
                // onClick={() => setIsOpen(true)}
                eventKey="2"
                className={`mb-1 ${
                  activeAccordionItem === "2" ? "active" : ""
                }`}
                onClick={() => handleAccordionClick("2")}
              >
                <Accordion.Header>
                  <div className="text-clr  me-2">
                    <FontAwesomeIcon
                      icon="fa-solid fa-gear"
                      onClick={() => setIsOpen(!isOpen)}
                    />

                    <b></b>
                    <b></b>
                  </div>
                  <p
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    Settings
                  </p>
                </Accordion.Header>
                <Accordion.Body
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  <ul className=" list-unstyled fw-normal pb-1 small">
                    {isAllow.includes(9) ? (
                      <Link
                        to="/masters/country"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/masters/country")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() => handleInnerListItemClick("country")}
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Country
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {/* State */}
                    {isAllow.includes(13) ? (
                      <Link
                        to="/masters/state"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/masters/state")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() => handleInnerListItemClick("state")}
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            State
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {/* City */}

                    {isAllow.includes(17) ? (
                      <Link to="/masters/city" className="text-decoration-none">
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/masters/city")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() => handleInnerListItemClick("city")}
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            City
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {/* Zone */}

                    {isAllow.includes(21) ? (
                      <Link to="/masters/zone" className="text-decoration-none">
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/masters/zone")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() => handleInnerListItemClick("zone")}
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Zone
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {/* Pincode */}

                    {isAllow.includes(25) ? (
                      <li
                        className={`${
                          isOpen
                            ? headerText.includes("/masters/pincode")
                              ? "active block"
                              : "none"
                            : "none"
                        }`}
                        onClick={() => handleInnerListItemClick("pincode")}
                      >
                        <Link
                          to="/masters/pincode"
                          className="text-decoration-none"
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Pincode
                          </p>
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    {/*Education List*/}

                    {isAllow.includes(29) ? (
                      <Link
                        to="/masters/education_list"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/masters/education_list")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() =>
                            handleInnerListItemClick("education_list")
                          }
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Education List
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {/* Order Status */}

                    {isAllow.includes(33) ? (
                      <Link
                        to="/masters/order_status"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/masters/order_status")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() =>
                            handleInnerListItemClick("order_status")
                          }
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Order Status
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {/* Payment Status */}

                    {isAllow.includes(37) ? (
                      <Link
                        to="/masters/payment_status"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/masters/payment_status")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() =>
                            handleInnerListItemClick("payment_status")
                          }
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Payment Status
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {/* GST List */}

                    {isAllow.includes(41) ? (
                      <Link
                        to="/masters/gstlist"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/masters/gstlist")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() => handleInnerListItemClick("gstlist")}
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            GST List
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {isAllow.includes(45) ? (
                      <Link
                        to="/masters/country_code"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/masters/country_code")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() =>
                            handleInnerListItemClick("country_code")
                          }
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Country Code
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {isAllow.includes(49) ? (
                      <Link
                        to="/masters/initial"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/masters/initial")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() => handleInnerListItemClick("initial")}
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Initial
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {isAllow.includes(49) ? (
                      <Link
                        to="/masters/currency"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/masters/currency")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() => handleInnerListItemClick("currency")}
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Currency
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {/* <li
                      className={`${
                        isOpen
                          ? headerText.includes("/projectmanegment/task_label")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("task_label")}
                    >
                      <Link
                        to="/projectmanegment/task_label"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Task Label
                        </p>
                      </Link>
                    </li> */}

                    {/* <li
                      className={`${
                        isOpen
                          ? headerText.includes(
                              "/projectmanegment/task_priority"
                            )
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("task_priority")}
                    >
                      <Link
                        to="/projectmanegment/task_priority"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Task Priority
                        </p>
                      </Link>
                    </li> */}

                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/complaint_types")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("complaint_types")
                      }
                    >
                      <Link
                        to="/masters/complaint_types"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Complaint Type
                        </p>
                      </Link>
                    </li>

                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/visit_types")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("visit_types")}
                    >
                      <Link
                        to="/masters/visit_types"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Visit Type
                        </p>
                      </Link>
                    </li>

                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/complaint_source")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("complaint_source")
                      }
                    >
                      <Link
                        to="/masters/complaint_source"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Complaint Source
                        </p>
                      </Link>
                    </li>

                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/complaint_priorities")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("complaint_priorities")
                      }
                    >
                      <Link
                        to="/masters/complaint_priorities"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Complaint Priorities
                        </p>
                      </Link>
                    </li>

                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/complaint_status")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() =>
                        handleInnerListItemClick("complaint_status")
                      }
                    >
                      <Link
                        to="/masters/complaint_status"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Complaint Status
                        </p>
                      </Link>
                    </li>

                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/visit_rate")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("visit_rate")}
                    >
                      <Link
                        to="/masters/visit_rate"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Visit Rate
                        </p>
                      </Link>
                    </li>

                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/masters/customer")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("customer")}
                    >
                      <Link
                        to="/masters/customer"
                        className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                      >
                        <p
                          // onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Customer
                        </p>
                      </Link>
                    </li>
                  </ul>
                  {/* </div> */}
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <></>
            )}

            {/* ****************************************************Employee management************************************** */}

            {[1, 2, 3, 4, 5, 6, 7, 8].some((value) =>
              isAllow.includes(value)
            ) ? (
              <Accordion.Item
                eventKey="3"
                className={`mb-1 ${
                  activeAccordionItem === "3" ? "active" : ""
                }`}
                onClick={() => handleAccordionClick("3")}
              >
                <Accordion.Header>
                  <div className=" text-clr  me-2">
                    <FontAwesomeIcon
                      icon="fa-solid fa-sitemap"
                      onClick={() => setIsOpen(!isOpen)}
                    />

                    <b></b>
                    <b></b>
                  </div>
                  <p style={{ display: isOpen ? "block" : "none" }}>
                    Employee Management
                  </p>
                </Accordion.Header>
                <Accordion.Body
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  <ul className=" list-unstyled fw-normal pb-1 small">
                    {isAllow.includes(1) ? (
                      <Link
                        to="/employee/role"
                        className="text-decoration-none "
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/employee/role")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() => handleInnerListItemClick("role")}
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Role
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {isAllow.includes(5) ? (
                      <li
                        className={`${
                          isOpen
                            ? headerText.includes("/employee/employee_details")
                              ? "active block"
                              : "none"
                            : "none"
                        }`}
                        onClick={() =>
                          handleInnerListItemClick("employee_details")
                        }
                      >
                        <Link
                          to="/employee/employee_details"
                          className="text-decoration-none"
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Employee Details
                          </p>
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <></>
            )}

            {/* ************************************************Route Management******************************************************* */}
            {[81, 82, 83, 84].some((value) => isAllow.includes(value)) ? (
              <Accordion.Item
                eventKey="4"
                className={`mb-1 ${
                  activeAccordionItem === "4" ? "active" : ""
                }`}
                onClick={() => handleAccordionClick("4")}
              >
                <Accordion.Header>
                  <div className="text-clr  me-2">
                    <FontAwesomeIcon
                      icon="fa-solid fa-database"
                      onClick={() => setIsOpen(!isOpen)}
                    />

                    <b></b>
                    <b></b>
                  </div>
                  <p style={{ display: isOpen ? "block" : "none" }}>
                    Route Management
                  </p>
                </Accordion.Header>
                <Accordion.Body
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  <ul className=" list-unstyled fw-normal pb-1 small">
                    {isAllow.includes(81) ? (
                      <Link
                        to="/route_management/routelist"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes(
                                  "/route_management/routelist"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() => handleInnerListItemClick("routelist")}
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Route list
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <></>
            )}

            {/* *****************************************Co Ordinator Management********************************************** */}
            {[105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116].some(
              (value) => isAllow.includes(value)
            ) ? (
              <Accordion.Item
                eventKey="5"
                className={`mb-1 ${
                  activeAccordionItem === "5" ? "active" : ""
                }`}
                onClick={() => handleAccordionClick("5")}
              >
                <Accordion.Header>
                  <div className="text-clr  me-2">
                    <FontAwesomeIcon
                      icon="fa-solid fa-database"
                      onClick={() => setIsOpen(!isOpen)}
                    />

                    <b></b>
                    <b></b>
                  </div>
                  <p style={{ display: isOpen ? "block" : "none" }}>
                    Co-Ordinator Management
                  </p>
                </Accordion.Header>
                <Accordion.Body
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  <ul className=" list-unstyled fw-normal pb-1 small">
                    {isAllow.includes(105) ? (
                      <Link
                        to="/co_ordinator_management/manager"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes(
                                  "/co_ordinator_management/manager"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() => handleInnerListItemClick("manager")}
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Manager
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {isAllow.includes(109) ? (
                      <Link
                        to="/co_ordinator_management/employee_under_manager"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes(
                                  "/co_ordinator_management/employee_under_manager"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() =>
                            handleInnerListItemClick("employee_under_manager")
                          }
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Sales Co-Ordinater
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {isAllow.includes(113) ? (
                      <Link
                        to="/co_ordinator_management/sales_executive"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes(
                                  "/co_ordinator_management/sales_executive"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() =>
                            handleInnerListItemClick("sales_executive")
                          }
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Sales Executive
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <></>
            )}

            {/* ***************************************************HRM***************************************************************** */}

            {[
              61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76,
              77, 78, 79, 80,
            ].some((value) => isAllow.includes(value)) ? (
              <Accordion.Item
                eventKey="6"
                className={`mb-1 ${
                  activeAccordionItem === "6" ? "active" : ""
                }`}
                onClick={() => handleAccordionClick("6")}
              >
                <Accordion.Header>
                  <div className="text-clr   me-2">
                    <FontAwesomeIcon
                      icon="fa-solid fa-ticket-simple"
                      onClick={() => setIsOpen(!isOpen)}
                    />

                    <b></b>
                    <b></b>
                  </div>
                  <p style={{ display: isOpen ? "block" : "none" }}>
                    HR Management
                  </p>
                </Accordion.Header>
                <Accordion.Body
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  <ul className=" list-unstyled fw-normal pb-1 small">
                    {isAllow.includes(61) ? (
                      <Link
                        to="/hrm/department"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/hrm/department")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() => handleInnerListItemClick("department")}
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Depertment
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </ul>

                  <ul className=" list-unstyled fw-normal pb-1 small">
                    {isAllow.includes(65) ? (
                      <Link
                        to="/hrm/designation"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/hrm/designation")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() =>
                            handleInnerListItemClick("designation")
                          }
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Designation
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </ul>

                  <ul className=" list-unstyled fw-normal pb-1 small">
                    {isAllow.includes(69) ? (
                      <Link to="/hrm/skills" className="text-decoration-none">
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/hrm/skills")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() => handleInnerListItemClick("skills")}
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Skills
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </ul>

                  <ul className=" list-unstyled fw-normal pb-1 small">
                    <li
                      className={`${
                        isOpen
                          ? headerText.includes("/hrm/holiday")
                            ? "active block"
                            : "none"
                          : "none"
                      }`}
                      onClick={() => handleInnerListItemClick("holiday")}
                    >
                      <Link to="/hrm/holiday" className="text-decoration-none">
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Holiday
                        </p>
                      </Link>
                    </li>
                  </ul>

                  <ul className=" list-unstyled fw-normal pb-1 small">
                    {isAllow.includes(73) ? (
                      <Link
                        to="/hrm/leave_type"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/hrm/leave_type")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() => handleInnerListItemClick("leave_type")}
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Leave Type
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </ul>

                  <ul className=" list-unstyled fw-normal pb-1 small">
                    {isAllow.includes(77) ? (
                      <Link to="/hrm/leave" className="text-decoration-none">
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/hrm/leave")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() => handleInnerListItemClick("leave")}
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Leave
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </ul>

                  <ul className=" list-unstyled fw-normal pb-1 small">
                    <Link to="/hrm/attendance" className="text-decoration-none">
                      <li
                        className={`${
                          isOpen
                            ? headerText.includes("/hrm/attendance")
                              ? "active block"
                              : "none"
                            : "none"
                        }`}
                        onClick={() => handleInnerListItemClick("attendance")}
                      >
                        <p
                          onClick={() => setIsOpen(!isOpen)}
                          style={{ display: isOpen ? "block" : "none" }}
                        >
                          Attendance
                        </p>
                      </li>
                    </Link>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <></>
            )}

            {/* *****************************************Product Management********************************************** */}

            {[85, 86, 87, 88].some((value) => isAllow.includes(value)) ? (
              <Accordion.Item
                eventKey="7"
                className={`mb-1 ${
                  activeAccordionItem === "7" ? "active" : ""
                }`}
                onClick={() => handleAccordionClick("7")}
              >
                <Accordion.Header>
                  <div className="text-clr  me-2">
                    <FontAwesomeIcon
                      icon="fa-solid fa-database"
                      onClick={() => setIsOpen(!isOpen)}
                    />

                    <b></b>
                    <b></b>
                  </div>
                  <p style={{ display: isOpen ? "block" : "none" }}>
                    Product Management
                  </p>
                </Accordion.Header>
                <Accordion.Body
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  <ul className=" list-unstyled fw-normal pb-1 small">
                    {isAllow.includes(85) ? (
                      <Link
                        to="/product_management/product_list"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes(
                                  "/product_management/product_list"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() =>
                            handleInnerListItemClick("product_list")
                          }
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Product List
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <></>
            )}

            {/* *****************************************Lead Management********************************************** */}

            {[
              89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103,
              104,
            ].some((value) => isAllow.includes(value)) ? (
              <Accordion.Item
                eventKey="8"
                className={`mb-1 ${
                  activeAccordionItem === "8" ? "active" : ""
                }`}
                onClick={() => handleAccordionClick("8")}
              >
                <Accordion.Header>
                  <div className="text-clr  me-2">
                    <FontAwesomeIcon
                      icon="fa-solid fa-database"
                      onClick={() => setIsOpen(!isOpen)}
                    />

                    <b></b>
                    <b></b>
                  </div>
                  <p style={{ display: isOpen ? "block" : "none" }}>
                    Lead Management
                  </p>
                </Accordion.Header>
                <Accordion.Body
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  <ul className=" list-unstyled fw-normal pb-1 small">
                    {isAllow.includes(89) ? (
                      <Link
                        to="/lead_management/lead_status"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes(
                                  "/lead_management/lead_status"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() =>
                            handleInnerListItemClick("lead_status")
                          }
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Lead Status
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {isAllow.includes(93) ? (
                      <li
                        className={`${
                          isOpen
                            ? headerText.includes(
                                "/lead_management/lead_status_details"
                              )
                              ? "active block"
                              : "none"
                            : "none"
                        }`}
                        onClick={() =>
                          handleInnerListItemClick("lead_status_details")
                        }
                      >
                        <Link
                          to="/lead_management/lead_status_details"
                          className="text-decoration-none"
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Lead Status Details
                          </p>
                        </Link>
                      </li>
                    ) : (
                      <></>
                    )}

                    {/* {isAllow.includes(97) ||
                    isAllow.includes(98) ||
                    isAllow.includes(99) ||
                    isAllow.includes(100) ? (
                      <Link
                        to="/lead_management/document"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/lead_management/document")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() => handleInnerListItemClick("document")}
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Documents
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )} */}

                    {isAllow.includes(101) ? (
                      <Link
                        to="/lead_management/service"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/lead_management/service")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() => handleInnerListItemClick("service")}
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Service
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {isAllow.includes(93) ? (
                      <Link
                        to="/lead_management/leads"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/lead_management/leads")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() => handleInnerListItemClick("leads")}
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Leads
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <></>
            )}

            {/* ****************************************************************Ticket Management******************************************* */}

            {[
              45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60,
            ].some((value) => isAllow.includes(value)) ? (
              <Accordion.Item
                eventKey="9"
                className={`mb-1 ${
                  activeAccordionItem === "9" ? "active" : ""
                }`}
                onClick={() => handleAccordionClick("9")}
              >
                <Accordion.Header>
                  <div className="text-clr  me-2">
                    <FontAwesomeIcon
                      icon="fa-solid fa-ticket-simple"
                      onClick={() => setIsOpen(!isOpen)}
                    />
                    <b></b>
                    <b></b>
                  </div>
                  <p style={{ display: isOpen ? "block" : "none" }}>
                    Ticket Management
                  </p>
                </Accordion.Header>
                <Accordion.Body
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  <ul className=" list-unstyled fw-normal pb-1 small">
                    {isAllow.includes(45) ? (
                      <Link
                        to="/ticket_management/ticket_status"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes(
                                  "/ticket_management/ticket_status"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() =>
                            handleInnerListItemClick("ticket_status")
                          }
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Ticket Status
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {isAllow.includes(49) ? (
                      <Link
                        to="/ticket_management/priorities"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes(
                                  "/ticket_management/priorities"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() => handleInnerListItemClick("priorities")}
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Priorities
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {isAllow.includes(53) ? (
                      <Link
                        to="/ticket_management/ticket_types"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes(
                                  "/ticket_management/ticket_types"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() =>
                            handleInnerListItemClick("ticket_types")
                          }
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Ticket Types
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {isAllow.includes(57) ? (
                      <Link
                        to="/ticket_management/chanel_name"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes(
                                  "/ticket_management/chanel_name"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() =>
                            handleInnerListItemClick("chanel_name")
                          }
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Chanel Name
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}

                    {isAllow.includes(117) ? (
                      <Link
                        to="/ticket_management/ticket_generates"
                        className="text-decoration-none"
                      >
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes(
                                  "/ticket_management/ticket_generates"
                                )
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() =>
                            handleInnerListItemClick("ticket_generates")
                          }
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Ticket Generates
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <></>
            )}

            {/* ********************************************App Set Up ******************************************************************** */}

            {[1, 2, 3, 4, 5, 6, 7, 8].some((value) =>
              isAllow.includes(value)
            ) ? (
              <Accordion.Item
                eventKey="10"
                className={`mb-1 ${
                  activeAccordionItem === "10" ? "active" : ""
                }`}
                onClick={() => handleAccordionClick("10")}
              >
                <Accordion.Header>
                  <div className=" text-clr  me-2">
                    <FontAwesomeIcon
                      icon="fa-solid fa-sitemap"
                      onClick={() => setIsOpen(!isOpen)}
                    />

                    <b></b>
                    <b></b>
                  </div>
                  <p style={{ display: isOpen ? "block" : "none" }}>
                    App SetUp
                  </p>
                </Accordion.Header>
                <Accordion.Body
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  <ul className=" list-unstyled fw-normal pb-1 small">
                    {isAllow.includes(1) ? (
                      <Link to="/app_setup" className="text-decoration-none ">
                        <li
                          className={`${
                            isOpen
                              ? headerText.includes("/app_setup")
                                ? "active block"
                                : "none"
                              : "none"
                          }`}
                          onClick={() => handleInnerListItemClick("app_setup")}
                        >
                          <p
                            onClick={() => setIsOpen(!isOpen)}
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            App Setup
                          </p>
                        </li>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              <></>
            )}

            {/* ****************************************************************Project Management ******************************************* */}
            {/* <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="10"
              className={`mb-1 ${activeAccordionItem === "10" ? "active" : ""}`}
              onClick={() => handleAccordionClick("10")}
            >
              <Accordion.Header>
                <div className="text-clr  me-2">
                  <FontAwesomeIcon
                    icon="fa-solid fa-gear"
                    onClick={() => setIsOpen(!isOpen)}
                  />
                  <b></b>
                  <b></b>
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Project Management
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  <li
                    className={`${isOpen
                      ? headerText.includes("/projectmanegment/task_category")
                        ? "active block"
                        : "none"
                      : "none"
                      }`}
                    onClick={() => handleInnerListItemClick("task_category")}
                  >
                    <Link
                      to="/projectmanegment/task_category"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Task Category
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${isOpen
                      ? headerText.includes(
                        "/projectmanegment/project_category"
                      )
                        ? "active block"
                        : "none"
                      : "none"
                      }`}
                    onClick={() => handleInnerListItemClick("project_category")}
                  >
                    <Link
                      to="/projectmanegment/project_category"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Project Category
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${isOpen
                      ? headerText.includes(
                        "/projectmanegment/discussion_category"
                      )
                        ? "active block"
                        : "none"
                      : "none"
                      }`}
                    onClick={() =>
                      handleInnerListItemClick("discussion_category")
                    }
                  >
                    <Link
                      to="/projectmanegment/discussion_category"
                      className="link-body-emphasis d-inline-flex text-decoration-none rounded"
                    >
                      <p
                        // onClick={() => setIsOpen(!isOpen)}
                        style={{ display: isOpen ? "block" : "none" }}
                      >
                        Discussion Category
                      </p>
                    </Link>
                  </li>

                 

                  <li
                    className={`${isOpen
                      ? headerText.includes("/projectManagement/projects")
                        ? "active block"
                        : "none"
                      : "none"
                      }`}
                    onClick={() =>
                      handleInnerListItemClick("/projectManagement/projects")
                    }
                  >
                    <Link to="/projectManagement/projects">
                     
                      <p style={{ display: isOpen ? "block" : "none" }}>
                        Projects
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${isOpen
                      ? headerText.includes("/project-management/tasks")
                        ? "active block"
                        : "none"
                      : "none"
                      }`}
                    onClick={() =>
                      handleInnerListItemClick("/project-management/tasks")
                    }
                  >
                    <Link to="/project-management/tasks">
                     
                      <p style={{ display: isOpen ? "block" : "none" }}>
                        Tasks
                      </p>
                    </Link>
                  </li>

                  <li
                    className={`${isOpen
                      ? headerText.includes("/projectmanegment/timeLog")
                        ? "active block"
                        : "none"
                      : "none"
                      }`}
                    onClick={() =>
                      handleInnerListItemClick("/projectmanegment/timeLog")
                    }
                  >
                    <Link to="/projectmanegment/timeLog">
                     
                      <p style={{ display: isOpen ? "block" : "none" }}>
                        Time Log
                      </p>
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item> */}

            {/* <Accordion.Item
              eventKey="10"
              className={`mb-1 projecttttabb ${activeAccordionItem === "10" ? "active" : ""
                }`}
              onClick={() => handleAccordionClick("10")}>
              <Link to='/projects'>
                <Accordion.Header>
                  <div className=" text-clr  me-2">
                    <FontAwesomeIcon icon="fa-solid fa-sitemap" onClick={() => setIsOpen(!isOpen)} />
                  </div>
                  <p style={{ display: isOpen ? "block" : "none" }}>
                    Projects
                  </p>
                </Accordion.Header>
              </Link>

            </Accordion.Item> */}

            {/* Client Management */}

            {/* <Accordion.Item
              // eventKey="2"
              // className="mb-1"
              // onClick={() => setIsOpen(true)}
              eventKey="11"
              className={`mb-1 ${activeAccordionItem === "11" ? "active" : ""}`}
              onClick={() => handleAccordionClick("11")}
            >
              <Accordion.Header>
                <div className="text-clr  me-2">
                  <FontAwesomeIcon
                    icon="fa-solid fa-gear"
                    onClick={() => setIsOpen(!isOpen)}
                  />
                  <b></b>
                  <b></b>
                </div>
                <p
                  style={{
                    display: isOpen ? "block" : "none",
                  }}
                >
                  Client Management
                </p>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  display: isOpen ? "block" : "none",
                }}
              >
                <ul className=" list-unstyled fw-normal pb-1 small">
                  <li
                    className={`${isOpen
                      ? headerText.includes("clientManagement/client")
                        ? "active block"
                        : "none"
                      : "none"
                      }`}
                    onClick={() =>
                      handleInnerListItemClick("clientManagement/client")
                    }
                  >
                    <Link to="clientManagement/client">
                    
                      <p style={{ display: isOpen ? "block" : "none" }}>
                        Client
                      </p>
                    </Link>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item> */}
          </Accordion>
        </ul>
      </section>
    </>
  );
};

export default Sidebar;
