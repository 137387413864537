import React, { useState } from 'react'
import { Col, Form, Nav, Row, Tab } from 'react-bootstrap'
import Select from 'react-select';


const city = [
    { value: 'nashik', label: 'nashik' },
    { value: 'pune', label: 'pune' },
    { value: 'mumbai', label: 'mumbai' },
];

const ResidentialAddress = ({
    getValues,
    register,
    classNames,
    errors,
    Controller,
    control,
    countries,
    states,
    cities,
    pincodes,
    GetAllState,
    GetAllCities,
    GetAllPincodes,
    setValue,
    disabled
}) => {

    const [selectedCity, setSelectedCity] = useState(null);

    return (
        <div>
            <div className='below-formmmmm'>
                <Form>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Nav variant="pills" className="flex-row">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Current address</Nav.Link>
                                    {(errors?.currentadd?.country_id || errors?.currentadd?.state_id || errors?.currentadd?.city_id ||
                                        errors?.currentadd?.address || errors?.currentadd?.pincode_id) && (
                                            <span className="text-danger ms-5">
                                                {"Fill All Fields 👇"}
                                            </span>
                                        )}
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Permanent address</Nav.Link>
                                    {(errors?.permanentadd?.country_id || errors?.permanentadd?.state_id || errors?.permanentadd?.city_id ||
                                        errors?.permanentadd?.address || errors?.permanentadd?.pincode_id) && (
                                            <span className="text-danger ms-5">
                                                {"Fill All Fields 👇"}
                                            </span>
                                        )}
                                </Nav.Item>

                            </Nav>
                            <Tab.Content className='mt-4'>
                                <Tab.Pane eventKey="first">
                                    <Row>
                                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Current address</Form.Label>
                                                <Form.Control
                                                    disabled={disabled}
                                                    type="text"
                                                    name={`currentadd.address`}
                                                    placeholder="Current address"
                                                    className={classNames("", {
                                                        "is-invalid": errors?.currentadd?.address,
                                                    })}
                                                    {...register(`currentadd.address`, {
                                                        required: "Address is required",
                                                    })}
                                                />
                                                {errors?.currentadd?.address && (
                                                    <div className="invalid-feedback">
                                                        {errors?.currentadd?.address?.message}
                                                    </div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Country</Form.Label>
                                                <Controller
                                                    name={`currentadd.country_id`} // name of the field
                                                    control={control}
                                                    rules={{
                                                        required: "Select Country",
                                                    }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors?.currentadd?.country_id ? "red" : baseStyles.borderColor,
                                                                }),
                                                            }}
                                                            // {...field} 
                                                            options={countries}
                                                            onChange={(selectedValue) => {
                                                                onChange(selectedValue);
                                                                GetAllState(selectedValue.value);
                                                                setValue("currentadd.state_id", "");
                                                                setValue("currentadd.city_id", "");
                                                                setValue("currentadd.pincode_id", "");
                                                            }}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            ref={ref}
                                                            isDisabled={disabled}
                                                        />
                                                    )}
                                                />
                                                {errors?.currentadd?.country_id && (
                                                    <span className="text-danger">
                                                        {errors?.currentadd?.country_id.message}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>State</Form.Label>
                                                <Controller
                                                    name={`currentadd.state_id`} // name of the field
                                                    control={control}
                                                    rules={{
                                                        required: "Select State",
                                                    }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors?.currentadd?.state_id ? "red" : baseStyles.borderColor,
                                                                }),
                                                            }}
                                                            // {...field}
                                                            options={states}
                                                            onChange={(selectedValue) => {
                                                                onChange(selectedValue);
                                                                GetAllCities(selectedValue.value);
                                                                setValue("currentadd.city_id", "");
                                                                setValue("currentadd.pincode_id", "");
                                                            }}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            ref={ref}
                                                            isDisabled={disabled}
                                                        />
                                                    )}
                                                />
                                                {errors?.currentadd?.state_id && (
                                                    <span className="text-danger">
                                                        {errors?.currentadd?.state_id.message}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>City</Form.Label>
                                                <Controller
                                                    name={`currentadd.city_id`} // name of the field
                                                    control={control}
                                                    rules={{
                                                        required: "Select City",
                                                    }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors?.currentadd?.city_id ? "red" : baseStyles.borderColor,
                                                                }),
                                                            }}
                                                            // {...field}
                                                            options={cities}
                                                            onChange={(selectedValue) => {
                                                                onChange(selectedValue);
                                                                GetAllPincodes(selectedValue.value);
                                                                setValue("currentadd.pincode_id", "");
                                                            }}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            ref={ref}
                                                            isDisabled={disabled}
                                                        />
                                                    )}
                                                />
                                                {errors?.currentadd?.city_id && (
                                                    <span className="text-danger">
                                                        {errors?.currentadd?.city_id.message}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Pincode</Form.Label>
                                                <Controller
                                                    name={`currentadd.pincode_id`} // name of the field
                                                    control={control}
                                                    rules={{
                                                        required: "Select City",
                                                    }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors?.currentadd?.pincode_id ? "red" : baseStyles.borderColor,
                                                                }),
                                                            }}
                                                            // {...field}
                                                            options={pincodes}
                                                            onChange={(selectedValue) => {
                                                                onChange(selectedValue);
                                                            }}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            ref={ref}
                                                            isDisabled={disabled}
                                                        />
                                                    )}
                                                />
                                                {errors?.currentadd?.pincode_id && (
                                                    <span className="text-danger">
                                                        {errors?.currentadd?.pincode_id.message}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Form.Label>Duration Of stay</Form.Label>
                                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>From</Form.Label>
                                                <Form.Control
                                                    disabled={disabled}
                                                    type="date"
                                                    name={`currentadd.from_date`}
                                                    placeholder="From Date"
                                                    className={classNames("", {
                                                        "is-invalid": errors?.currentadd?.from_date, // Adjusted error checking
                                                    })}
                                                    {...register(`currentadd.from_date`, {
                                                        required: "Date required",
                                                    })}
                                                />
                                                {errors?.currentadd?.from_date && (
                                                    <div className="invalid-feedback">
                                                        {errors.currentadd.from_date.message} {/* Display the error message */}
                                                    </div>
                                                )}
                                            </Form.Group>
                                        </Col>

                                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>

                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>To </Form.Label>
                                                <Form.Control
                                                    disabled={disabled}
                                                    type="date"
                                                    name={`currentadd.to_date`}
                                                    placeholder="To Date"
                                                    className={classNames("", {
                                                        "is-invalid": errors?.currentadd?.to_date, // Adjusted error checking
                                                    })}
                                                    {...register(`currentadd.to_date`, {
                                                        required: "Date required",
                                                    })}
                                                />
                                                {errors?.currentadd?.to_date && (
                                                    <div className="invalid-feedback">
                                                        {errors.currentadd.to_date.message} {/* Display the error message */}
                                                    </div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <Row>
                                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Permanent address</Form.Label>
                                                <Form.Control
                                                    disabled={disabled}
                                                    type="text"
                                                    name={`permanentadd.address`}
                                                    placeholder="Current address"
                                                    className={classNames("", {
                                                        "is-invalid": errors?.permanentadd?.address,
                                                    })}
                                                    {...register(`permanentadd.address`, {
                                                        required: "Address is required",
                                                    })}
                                                />
                                                {errors?.permanentadd?.address && (
                                                    <div className="invalid-feedback">
                                                        {errors?.permanentadd?.address?.message}
                                                    </div>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Country</Form.Label>
                                                <Controller
                                                    name={`permanentadd.country_id`} // name of the field
                                                    control={control}
                                                    rules={{
                                                        required: "Select Country",
                                                    }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors?.permanentadd?.country_id ? "red" : baseStyles.borderColor,
                                                                }),
                                                            }}
                                                            // {...field} 
                                                            options={countries}
                                                            onChange={(selectedValue) => {
                                                                onChange(selectedValue);
                                                                GetAllState(selectedValue.value);
                                                                setValue("permanentadd.state_id", "");
                                                                setValue("permanentadd.city_id", "");
                                                                setValue("permanentadd.pincode_id", "");
                                                            }}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            ref={ref}
                                                            isDisabled={disabled}
                                                        />
                                                    )}
                                                />
                                                {errors?.permanentadd?.country_id && (
                                                    <span className="text-danger">
                                                        {errors?.permanentadd?.country_id.message}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>State</Form.Label>
                                                <Controller
                                                    name={`permanentadd.state_id`} // name of the field
                                                    control={control}
                                                    rules={{
                                                        required: "Select State",
                                                    }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors?.permanentadd?.state_id ? "red" : baseStyles.borderColor,
                                                                }),
                                                            }}
                                                            // {...field}
                                                            options={states}
                                                            onChange={(selectedValue) => {
                                                                onChange(selectedValue);
                                                                GetAllCities(selectedValue.value);
                                                                setValue("permanentadd.city_id", "");
                                                                setValue("permanentadd.pincode_id", "");
                                                            }}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            ref={ref}
                                                            isDisabled={disabled}
                                                        />
                                                    )}
                                                />
                                                {errors?.permanentadd?.state_id && (
                                                    <span className="text-danger">
                                                        {errors?.permanentadd?.state_id.message}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>City</Form.Label>
                                                <Controller
                                                    name={`permanentadd.city_id`} // name of the field
                                                    control={control}
                                                    rules={{
                                                        required: "Select City",
                                                    }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors?.permanentadd?.city_id ? "red" : baseStyles.borderColor,
                                                                }),
                                                            }}
                                                            // {...field}
                                                            options={cities}
                                                            onChange={(selectedValue) => {
                                                                onChange(selectedValue);
                                                                GetAllPincodes(selectedValue.value);
                                                                setValue("permanentadd.pincode_id", "");
                                                            }}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            ref={ref}
                                                            isDisabled={disabled}
                                                        />
                                                    )}
                                                />
                                                {errors?.permanentadd?.city_id && (
                                                    <span className="text-danger">
                                                        {errors?.permanentadd?.city_id.message}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                                            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Pincode</Form.Label>
                                                <Controller
                                                    name={`permanentadd.pincode_id`} // name of the field
                                                    control={control}
                                                    rules={{
                                                        required: "Select City",
                                                    }}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors?.permanentadd?.pincode_id ? "red" : baseStyles.borderColor,
                                                                }),
                                                            }}
                                                            // {...field}
                                                            options={pincodes}
                                                            onChange={(selectedValue) => {
                                                                onChange(selectedValue);
                                                            }}
                                                            onBlur={onBlur}
                                                            value={value}
                                                            ref={ref}
                                                            isDisabled={disabled}
                                                        />
                                                    )}
                                                />
                                                {errors?.permanentadd?.pincode_id && (
                                                    <span className="text-danger">
                                                        {errors?.permanentadd?.pincode_id.message}
                                                    </span>
                                                )}
                                            </Form.Group>
                                        </Col>

                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Row>
                    </Tab.Container>

                </Form>
            </div>
        </div>
    )
}

export default ResidentialAddress