import { Link } from "react-router-dom";

import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Modal } from "react-bootstrap";

import React, { useContext, useEffect, useState } from "react";

import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";

import { useForm } from "react-hook-form";
import classNames from "classnames";
import ModalSave from "../../../common/ModalSave";
import { Context } from "../../../../utils/context";
import { Controller, useFieldArray } from "react-hook-form";
import AddedLottieModal from "../../../Hrms-Dashboard/commonLottie/AddedLottieModal";
import { useNavigate } from "react-router";

import { format, isBefore, isAfter } from "date-fns";

const Add = () => {
  // const state = [
  //   { value: "Maharshtra", label: "Maharshtra" },
  //   { value: "Karnatak", label: "Karnatak" },
  //   { value: "Delhi", label: "Delhi" },
  // ];
  // const city = [
  //   { value: "Pune", label: "Pune" },
  //   { value: "Mumbai", label: "Mumbai" },
  //   { value: "Satara", label: "Satara" },
  // ];
  const navigate = useNavigate();
  const [showLottie, setshowLottie] = useState(false);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const { postData, Select2Data, getData } = useContext(Context);
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,

    watch,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      thired_party_api: [],
    },
  });

  //
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "thired_party_api",
    }
  );
  //

  console.log(getValues(), "getValues");

  const imageFile = watch("logo");

  const onSubmit = async (data) => {
    try {
      const sendData = new FormData();
      sendData.append("name", data?.name);
      sendData.append("email", data?.email);
      sendData.append("contact_no", data?.contact_no);
      sendData.append("whats_app_no", data?.whats_app_no);
      sendData.append("country_id", data?.country_id?.value);
      sendData.append("state_id", data?.state_id?.value);
      sendData.append("city_id", data?.city_id?.value);
      sendData.append("business_name", data?.business_name);
      sendData.append("pan_no", data?.pan_no);
      sendData.append("gst_no", data?.gst_no);
      sendData.append("address", data?.address);
      sendData.append("ac_holder_name", data?.ac_holder_name);
      sendData.append("ac_no", data?.ac_no);
      sendData.append("branch_name", data?.branch_name);
      sendData.append("ifsc", data?.ifsc);
      sendData.append("license_s_date", data?.license_s_date);
      sendData.append("license_e_date", data?.license_e_date);
      sendData.append("license_no", data?.license_no);
      sendData.append("password", data?.password);
      sendData.append("sub_domain", data?.sub_domain);
      sendData.append("database_name", data?.database_name);
      sendData.append("database_user_name", data?.database_user_name);
      sendData.append("database_password", data?.database_password);

      sendData.append(
        "thired_party_api",
        JSON.stringify(data?.thired_party_api)
      );

      if (data?.logo && data.logo.length > 0) {
        sendData.append("logo", data.logo[0]);
      }

      sendData.append("complaint", data?.complaint);
      sendData.append("hrms", data?.hrms);
      sendData.append("project", data?.project);
      sendData.append("lead", data?.lead);

      const response = await postData(
        `/super-admin/subscriptions/subscriptions`,
        sendData
      );

      if (response?.success) {
        setshowLottie(true);
        setTimeout(() => {
          setshowLottie(false);
          reset();
          navigate("/super-admin/subscriber");
          // props.onHide();
        }, 2000);
      } else {
        setshowLottie();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);

  const GetAllCountry = async () => {
    const response = await getData("/masters/allcountry");
    if (response?.success) {
      setCountry(await Select2Data(response?.data, "country_id"));
    }

    {
      const response = await getData("/masters/all-thired-party-api-charges");
      if (response?.success) {
        // setThired_party_api(response?.data);

        const formattedData = response?.data?.map((val) => ({
          name: val.name,
          thired_party_api_id: val.id,
          status: false,
          coins: "",
        }));
        // console.log(formattedData);
        setValue("thired_party_api", formattedData);
      }
    }
  };

  const GetAllState = async (id) => {
    const response = await getData(`/masters/allstate/${id}`);
    if (response?.success) {
      setState(await Select2Data(response?.data, "state_id"));
    }
  };

  const GetAllCity = async (id) => {
    const response = await getData(`/masters/allcity/${id}`);
    if (response?.success) {
      setCity(await Select2Data(response?.data, "city_id"));
    }
  };

  useEffect(() => {
    GetAllCountry();
    GetAllState();
    GetAllCity();
  }, []);

  // ----------------------------------------------------------------
  useEffect(() => {
    // Validate end date whenever start date changes
    if (startDate && endDate) {
      if (isBefore(endDate, startDate)) {
        setValue("license_e_date", ""); // Clear end date if it is before start date
        setEndDate(null); // Reset endDate state
      }
    }
  }, [startDate, endDate, setValue]);
  return (
    <>
      <section className="maintablesec">
        <div className="addsection">
          <h6 className="addtitle">Add Subscriber</h6>
        </div>

        <div className="addformseccc mt-5">
          <Form onSubmit={handleSubmit(onSubmit)} role="form">
            <div className="row">
              <div className="col-lg-4 mb-4">
                <Form.Label>Name</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Name"
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      {...register("name", {
                        required: "Name is required",
                      })}
                    />
                  </InputGroup>
                  {errors.name && (
                    <span className="text-danger">{errors.name.message}</span>
                  )}
                </Form.Group>
              </div>

              <div className="col-lg-4 mb-4">
                <Form.Label>Email</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Email"
                      className={classNames("", {
                        "is-invalid": errors?.email,
                      })}
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, // Regular expression for basic email format
                          message: "Please enter a valid email address",
                        },
                      })}
                    />
                  </InputGroup>
                  {errors.email && (
                    <span className="text-danger">{errors.email.message}</span>
                  )}
                </Form.Group>
              </div>

              <div className="col-lg-4 mb-4">
                <Form.Label>Mobile No</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    {/* <Form.Control
                      type="number"
                      name="contact_no"
                      maxLength={10}
                      placeholder="Mobile No"
                      className={classNames("", {
                        "is-invalid": errors?.contact_no,
                      })}
                      {...register("contact_no", {
                        required: "Mobile No is required",
                      })}
                    /> */}

                    <Form.Control
                      type="text" // Use "text" type to prevent browser number input issues
                      name="contact_no"
                      maxLength={10}
                      placeholder="Mobile No"
                      className={classNames("", {
                        "is-invalid": errors?.contact_no,
                      })}
                      {...register("contact_no", {
                        required: "Mobile No is required",
                        pattern: {
                          value: /^[0-9]*$/, // Regular expression to allow only numbers
                          message: "Only numbers are allowed",
                        },
                        minLength: {
                          value: 10,
                          message: "Mobile No must be exactly 10 digits",
                        },
                        maxLength: {
                          value: 10,
                          message: "Mobile No must be exactly 10 digits",
                        },
                      })}
                      onKeyPress={(e) => {
                        // Prevent non-numeric input
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </InputGroup>
                  {errors.contact_no && (
                    <span className="text-danger">
                      {errors.contact_no.message}
                    </span>
                  )}
                </Form.Group>
              </div>

              <div className="col-lg-4 mb-4">
                <Form.Label>WatsApp No</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    {/* <Form.Control
                      type="number"
                      name="whats_app_no"
                      placeholder="Watsapp no"
                      className={classNames("", {
                        "is-invalid": errors?.whats_app_no,
                      })}
                      {...register("whats_app_no", {
                        required: "Watsapp No is required",
                      })}
                    /> */}

                    <Form.Control
                      type="text"
                      maxLength={10}
                      placeholder="Watsapp no"
                      className={classNames("", {
                        "is-invalid": errors?.whats_app_no,
                      })}
                      {...register("whats_app_no", {
                        required: "Watsapp No is required",
                        pattern: {
                          value: /^[0-9]*$/, // Regular expression to allow only numbers
                          message: "Only numbers are allowed",
                        },
                        minLength: {
                          value: 10,
                          message: "Mobile No must be exactly 10 digits",
                        },
                        maxLength: {
                          value: 10,
                          message: "Mobile No must be exactly 10 digits",
                        },
                      })}
                      onKeyPress={(e) => {
                        // Prevent non-numeric input
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </InputGroup>
                  {errors.whats_app_no && (
                    <span className="text-danger">
                      {errors.whats_app_no.message}
                    </span>
                  )}
                </Form.Group>
              </div>

              <div className="col-lg-4 mb-4">
                <Row className="row justify-content-center mb-2">
                  <Form.Label>Country</Form.Label>
                  <Controller
                    name="country_id"
                    {...register("country_id", {
                      required: "Select Country",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.country_id ? "red" : baseStyles,
                          }),
                        }}
                        {...field}
                        options={country}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value);
                          GetAllState(selectedOption.value);
                          setValue("country_id", selectedOption);
                          setValue("state_id", "");
                          setValue("city_id", "");
                        }}
                      />
                    )}
                  />
                  {errors.category_id && (
                    <span className="text-danger">
                      {errors.category_id.message}
                    </span>
                  )}
                </Row>
              </div>

              <div className="col-lg-4 mb-4">
                <Row className="row justify-content-center mb-2">
                  <Form.Label>State</Form.Label>
                  <Controller
                    name="state_id"
                    {...register("state_id", {
                      required: "Select State",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.state_id ? "red" : baseStyles,
                          }),
                        }}
                        {...field}
                        options={state}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption.value);
                          GetAllCity(selectedOption.value);
                          setValue("state_id", selectedOption);
                          setValue("city_id", "");
                        }}
                      />
                    )}
                  />
                  {errors.category_id && (
                    <span className="text-danger">
                      {errors.category_id.message}
                    </span>
                  )}
                </Row>
              </div>
              <div className="col-lg-4 mb-4">
                <Row className="row justify-content-center mb-2">
                  <Form.Label>City</Form.Label>
                  <Controller
                    name="city_id"
                    {...register("city_id", {
                      required: "Select City",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.city_id ? "red" : baseStyles,
                          }),
                        }}
                        {...field}
                        options={city}
                      />
                    )}
                  />
                  {errors.city_id && (
                    <span className="text-danger">
                      {errors.city_id.message}
                    </span>
                  )}
                </Row>
              </div>
              <div className="col-lg-4 mb-4">
                <Form.Label>Business Name</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="business_name"
                      placeholder="Name"
                      className={classNames("", {
                        "is-invalid": errors?.business_name,
                      })}
                      {...register("business_name", {
                        required: "Name is required",
                      })}
                    />
                  </InputGroup>
                  {errors.business_name && (
                    <span className="text-danger">
                      {errors.business_name.message}
                    </span>
                  )}
                </Form.Group>
              </div>
              <div className="col-lg-4 mb-4">
                <Form.Label>Pan No</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="pan_no"
                      placeholder="Pan No"
                      className={classNames("", {
                        "is-invalid": errors?.pan_no,
                      })}
                      {...register("pan_no", {
                        required: "Pan No is required",
                      })}
                    />
                  </InputGroup>
                  {errors.pan_no && (
                    <span className="text-danger">{errors.pan_no.message}</span>
                  )}
                </Form.Group>
              </div>
              <div className="col-lg-4 mb-4">
                <Form.Label>Gst No</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="gst_no"
                      placeholder="GST"
                      className={classNames("", {
                        "is-invalid": errors?.gst_no,
                      })}
                      {...register("gst_no", {
                        required: "GST is required",
                      })}
                    />
                  </InputGroup>
                  {errors.gst_no && (
                    <span className="text-danger">{errors.gst_no.message}</span>
                  )}
                </Form.Group>
              </div>
              <div className="col-lg-4 mb-4">
                <Form.Label>Address</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="address"
                      placeholder="Address"
                      className={classNames("", {
                        "is-invalid": errors?.address,
                      })}
                      {...register("address", {
                        required: "Address is required",
                      })}
                    />
                  </InputGroup>
                  {errors.address && (
                    <span className="text-danger">
                      {errors.address.message}
                    </span>
                  )}
                </Form.Group>
              </div>
              <div className="col-lg-4 mb-4">
                <Form.Label>Account Holder Name</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="ac_holder_name"
                      placeholder="Account Holder Name"
                      className={classNames("", {
                        "is-invalid": errors?.ac_holder_name,
                      })}
                      {...register("ac_holder_name", {
                        required: "Account Holder Name is required",
                      })}
                    />
                  </InputGroup>
                  {errors.ac_holder_name && (
                    <span className="text-danger">
                      {errors.ac_holder_name.message}
                    </span>
                  )}
                </Form.Group>
              </div>
              <div className="col-lg-4 mb-4">
                <Form.Label>Account Number</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="ac_no"
                      placeholder="Account Number"
                      className={classNames("", {
                        "is-invalid": errors?.ac_no,
                      })}
                      {...register("ac_no", {
                        required: "Account Number is required",
                      })}
                    />
                  </InputGroup>
                  {errors.ac_no && (
                    <span className="text-danger">{errors.ac_no.message}</span>
                  )}
                </Form.Group>
              </div>
              <div className="col-lg-4 mb-4">
                <Form.Label>Branch Name</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="branch_name"
                      placeholder="Branch Name"
                      className={classNames("", {
                        "is-invalid": errors?.branch_name,
                      })}
                      {...register("branch_name", {
                        required: "Branch Name is required",
                      })}
                    />
                  </InputGroup>
                  {errors.branch_name && (
                    <span className="text-danger">
                      {errors.branch_name.message}
                    </span>
                  )}
                </Form.Group>
              </div>
              <div className="col-lg-4 mb-4">
                <Form.Label>IFSC Code</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="ifsc"
                      placeholder="IFSC Code"
                      className={classNames("", {
                        "is-invalid": errors?.ifsc,
                      })}
                      {...register("ifsc", {
                        required: "IFSC Code is required",
                      })}
                    />
                  </InputGroup>
                  {errors.ifsc && (
                    <span className="text-danger">{errors.ifsc.message}</span>
                  )}
                </Form.Group>
              </div>
              {/* <div className="col-lg-4 mb-4">
                <Form.Group className="form-group">
                  <Form.Label>
                    License start date<span className="mandatory-star">*</span>
                  </Form.Label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      const formattedDate = format(date, "dd/MM/yyyy"); // Format date to dd/MM/yyyy
                      setValue("license_s_date", formattedDate); // Manually set the value for react-hook-form
                    }}
                    className={classNames("form-control", {
                      "is-invalid": errors?.license_s_date,
                    })}
                    name="license_s_date"
                    id="deadline"
                    autoComplete="off"
                    placeholderText="DD/MM/YYYY"
                  />
                  <input
                    type="hidden"
                    {...register("license_s_date", {
                      required: "License start date is required",
                    })} // Register the input with react-hook-form
                  />
                </Form.Group>
                {errors.license_s_date && (
                  <span className="text-danger">
                    {errors.license_s_date.message}
                  </span>
                )}
              </div>

              <div className="col-lg-4 mb-4">
                <Form.Group className="form-group">
                  <Form.Label>
                    License End date<span className="mandatory-star">*</span>
                  </Form.Label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                      const formattedDate = format(date, "dd/MM/yyyy"); // Format date to dd/MM/yyyy
                      setValue("license_e_date", formattedDate); // Manually set the value for react-hook-form
                    }}
                    className={classNames("form-control", {
                      "is-invalid": errors?.license_e_date,
                    })}
                    name="license_e_date"
                    id="deadline"
                    autoComplete="off"
                    placeholderText="DD/MM/YYYY"
                  />
                  <input
                    type="hidden"
                    {...register("license_e_date", {
                      required: "License End date is required",
                    })} // Register the input with react-hook-form
                  />
                </Form.Group>
                {errors.license_e_date && (
                  <span className="text-danger">
                    {errors.license_e_date.message}
                  </span>
                )}
              </div> */}

              <div className="col-lg-4 mb-4">
                <Form.Group className="form-group">
                  <Form.Label>
                    License start date<span className="mandatory-star">*</span>
                  </Form.Label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      if (date) {
                        setStartDate(date);
                        const formattedDate = format(date, "MM/dd/yyyy"); // Format date to dd/MM/yyyy
                        setValue("license_s_date", formattedDate);
                      }
                    }}
                    className={classNames("form-control", {
                      "is-invalid": errors?.license_s_date,
                    })}
                    name="license_s_date"
                    id="deadline"
                    autoComplete="off"
                    placeholderText="MM/DD/YYYY"
                  />
                  <input
                    type="hidden"
                    {...register("license_s_date", {
                      required: "License start date is required",
                    })}
                  />
                </Form.Group>
                {errors.license_s_date && (
                  <span className="text-danger">
                    {errors.license_s_date.message}
                  </span>
                )}
              </div>

              <div className="col-lg-4 mb-4">
                <Form.Group className="form-group">
                  <Form.Label>
                    License End date<span className="mandatory-star">*</span>
                  </Form.Label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => {
                      if (date) {
                        setEndDate(date);
                        const formattedDate = format(date, "MM/dd/yyyy"); // Format date to MM/dd/yyyy
                        setValue("license_e_date", formattedDate);
                      }
                    }}
                    className={classNames("form-control", {
                      "is-invalid": errors?.license_e_date,
                    })}
                    name="license_e_date"
                    id="license_e_date"
                    autoComplete="off"
                    placeholderText="MM/DD/YYYY"
                    minDate={new Date()} // Prevent past dates
                  />
                  <input
                    type="hidden"
                    {...register("license_e_date", {
                      required: "License End date is required",
                      validate: (value) => {
                        const startDateValue = getValues("license_s_date");
                        const startDateObj = new Date(startDateValue);
                        return (
                          !value ||
                          isAfter(new Date(value), startDateObj) ||
                          "License End date must be after the Start date"
                        );
                      },
                    })}
                  />
                </Form.Group>
                {errors.license_e_date && (
                  <span className="text-danger">
                    {errors.license_e_date.message}
                  </span>
                )}
              </div>
              {/* <div className="col-lg-4 mb-4">
                <Form.Group className="form-group">
                  <Form.Label>
                    License End date<span className="mandatory-star">*</span>
                  </Form.Label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                      const formattedDate = format(date, "MM/dd/yyyy"); // Format date to dd/MM/yyyy
                      setValue("license_e_date", formattedDate);
                    }}
                    className={classNames("form-control", {
                      "is-invalid": errors?.license_e_date,
                    })}
                    name="license_e_date"
                    id="deadline"
                    autoComplete="off"
                    placeholderText="MM/DD/YYYY"
                  />
                  <input
                    type="hidden"
                    {...register("license_e_date", {
                      required: "License End date is required",
                    })}
                  />
                </Form.Group>
                {errors.license_e_date && (
                  <span className="text-danger">
                    {errors.license_e_date.message}
                  </span>
                )}
              </div> */}
              <div className="col-lg-4 mb-4">
                <Form.Label>Licence No</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="license_no"
                      placeholder="No Of Licence"
                      className={classNames("", {
                        "is-invalid": errors?.license_no,
                      })}
                      {...register("license_no", {
                        required: "Licence No is required",
                      })}
                    />
                  </InputGroup>
                  {errors.license_no && (
                    <span className="text-danger">
                      {errors.license_no.message}
                    </span>
                  )}
                </Form.Group>
              </div>
              <div className="col-lg-4 mb-4">
                <Form.Label>Password</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="name"
                      name="password"
                      placeholder="Password"
                      className={classNames("", {
                        "is-invalid": errors?.password,
                      })}
                      {...register("password", {
                        required: "Password is required",
                      })}
                    />
                  </InputGroup>
                  {errors.password && (
                    <span className="text-danger">
                      {errors.password.message}
                    </span>
                  )}
                </Form.Group>
              </div>

              <div className="col-lg-4 mb-4">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="name"
                      name="confirm_password"
                      placeholder="Confirm Password"
                      className={classNames("", {
                        "is-invalid": errors?.confirm_password,
                      })}
                      {...register("confirm_password", {
                        required: "Confirm password is required",
                        validate: (value) =>
                          value === watch("password") ||
                          "Passwords does not match",
                      })}
                    />
                  </InputGroup>
                  {errors.confirm_password && (
                    <span className="text-danger">
                      {errors.confirm_password.message}
                    </span>
                  )}
                </Form.Group>
              </div>

              <div className="col-lg-4 mb-4">
                <Form.Label>Sub Domain Name</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="sub_domain"
                      placeholder="Sub Domain Name"
                      className={classNames("", {
                        "is-invalid": errors?.sub_domain,
                      })}
                      {...register("sub_domain", {
                        required: "Sub Domain Name is required",
                      })}
                    />
                  </InputGroup>
                  {errors.sub_domain && (
                    <span className="text-danger">
                      {errors.sub_domain.message}
                    </span>
                  )}
                </Form.Group>
              </div>
              <div className="col-lg-4 mb-4">
                <Form.Label>Database Name</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="database_name"
                      placeholder="Database Name"
                      className={classNames("", {
                        "is-invalid": errors?.database_name,
                      })}
                      {...register("database_name", {
                        required: "Database Name is required",
                      })}
                    />
                  </InputGroup>
                  {errors.database_name && (
                    <span className="text-danger">
                      {errors.database_name.message}
                    </span>
                  )}
                </Form.Group>
              </div>
              <div className="col-lg-4 mb-4">
                <Form.Label>Database user Name</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="database_user_name"
                      placeholder="Name"
                      className={classNames("", {
                        "is-invalid": errors?.database_user_name,
                      })}
                      {...register("database_user_name", {
                        required: "Database User Name is required",
                      })}
                    />
                  </InputGroup>
                  {errors.database_user_name && (
                    <span className="text-danger">
                      {errors.database_user_name.message}
                    </span>
                  )}
                </Form.Group>
              </div>
              <div className="col-lg-4 mb-4">
                <Form.Label>Database Password</Form.Label>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="database_password"
                      placeholder="Database Password"
                      className={classNames("", {
                        "is-invalid": errors?.database_password,
                      })}
                      {...register("database_password", {
                        required: "Database Password is required",
                      })}
                    />
                  </InputGroup>
                  {errors.database_password && (
                    <span className="text-danger">
                      {errors.database_password.message}
                    </span>
                  )}
                </Form.Group>
              </div>

              <div className="col-lg-4 mb-4">
                <Row className="justify-content-start">
                  <Form.Label className="text-left">logo</Form.Label>

                  <Form.Group>
                    <Form.Control
                      className={classNames("", {
                        "is-invalid": errors?.logo,
                      })}
                      type="file"
                      {...register("logo", {
                        required: "Image is required",
                        validate: async (value) => {
                          if (typeof value !== "string") {
                            const fileTypes = ["jpg", "png", "jpeg"];
                            const fileType = value[0].name?.split(".")[1];

                            if (!fileTypes.includes(fileType)) {
                              return `please upload a valid file format. (${fileTypes})`;
                            }

                            // const sizes = await getDimension(value[0]);
                            // if (sizes.width !== 1296 && sizes.height !== 465) {
                            //   return "Image width and height must be 1296 px and 465 px";
                            // }

                            // const fileSize = Math.round(
                            //   value[0].size / 1024
                            // );
                            // if (fileSize > 500) {
                            //   return "file size must be lower than 500kb";
                            // }
                          }
                        },
                      })}
                      accept=".jpg, .jpeg, .png"
                    />
                  </Form.Group>
                  {errors.logo && (
                    <span className="text-danger">{errors.logo.message}</span>
                  )}
                </Row>
              </div>

              <Col className="main-form-section mt-3">
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>Logo Preview</Form.Label>

                    {imageFile && imageFile?.length > 0 && (
                      <div className="image-preview-container mt-3">
                        <img
                          // src={URL.createObjectURL(getValues("image")[0])}
                          src={URL?.createObjectURL(imageFile[0])}
                          alt="Preview"
                          className="image-preview"
                          width={"100px"}
                          height={"100px"}
                        />
                      </div>
                    )}
                  </Row>
                </div>
              </Col>

              <div className="col-lg-4 mb-4">
                <Form.Group className="form-group">
                  <Form.Label className="lablestirped">
                    Type Management<span className="mandatory-star">*</span>
                  </Form.Label>
                  <Form>
                    <Form.Check
                      inline
                      label="Complaint Management"
                      name="group1"
                      type="checkbox"
                      {...register(`complaint`)}
                      id={`inline-checkbox-1`}
                    />
                    <Form.Check
                      inline
                      label="HRMS Management"
                      name="group1"
                      type="checkbox"
                      {...register(`hrms`)}
                      id={`inline-checkbox-2`}
                    />
                    <Form.Check
                      inline
                      label="Project Management"
                      name="group1"
                      {...register(`project`)}
                      type="checkbox"
                      id={`inline-checkbox-3`}
                    />{" "}
                    <Form.Check
                      inline
                      label="Lead Management"
                      name="group1"
                      {...register(`lead`)}
                      type="checkbox"
                      id={`inline-checkbox-3`}
                    />
                  </Form>
                </Form.Group>
              </div>

              <div className="col-lg-12 mb-4">
                <div className="row">
                  <div className="col-lg-4">
                    <Form.Group className="form-group">
                      <Form.Label className="lablestirped">
                        Third Party API<span className="mandatory-star">*</span>
                      </Form.Label>
                      <Form>
                        {/* {thired_party_api?.map((val) => (
                          <div
                            key={`inline-${val?.id}`}
                            className="mb-3 d-flex"
                          >
                            <Form.Check
                              inline
                              label={val?.name}
                              name="group1"
                              type={"checkbox"}
                              {...register("thired_party_api", {
                                required: "thired_party_api is required",
                              })}
                              id={`inline-${val?.id}-1`}
                            />
                            <Form.Control
                              {...register("thired_party_api_coins", {
                                required: "thired_party_api_coins is required",
                              })}
                              type="text"
                              placeholder="Enter Coins"
                            />
                          </div>
                        ))} */}

                        {fields?.map((field, index) => (
                          <div
                            key={`inline-${field?.id}`}
                            className="mb-3 d-flex"
                          >
                            <Form.Check
                              inline
                              label={field?.name}
                              type={"checkbox"}
                              {...register(
                                `thired_party_api.${index}.status`,
                                {}
                              )} // Status checkbox for each API
                              id={`inline-${field?.id}-1`}
                            />
                            <Form.Control
                              className={classNames("", {
                                "is-invalid":
                                  errors?.thired_party_api?.[index]?.coins, // Check for coins validation error
                              })}
                              {...register(`thired_party_api.${index}.coins`, {
                                required: watch(
                                  `thired_party_api.${index}.status`
                                )
                                  ? {
                                      value: true,
                                      message: "Coins are required",
                                    }
                                  : false, // No validation if the checkbox is unchecked
                              })}
                              type="number"
                              placeholder="Enter Coins"
                            />
                            {errors?.thired_party_api?.[index]?.coins && (
                              <div className="invalid-feedback">
                                {errors.thired_party_api[index].coins.message}
                              </div>
                            )}
                          </div>
                        ))}
                      </Form>
                    </Form.Group>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 mb-4 text-center">
                <Form.Group className="form-group">
                  {" "}
                  <button type="submit" className="submit-btn btn me-2">
                    Submit
                  </button>
                  <button
                    type="button"
                    className="submit-btn btn"
                    onClick={() => navigate(`/super-admin/subscriber`)}
                  >
                    Close
                  </button>
                </Form.Group>
              </div>
            </div>
          </Form>
        </div>
      </section>
      <AddedLottieModal
        pageTitle="Subscription Added"
        show={showLottie}
        onHide={() => setshowLottie(false)}
      />
    </>
  );
};

export default Add;
