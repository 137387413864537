import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import SuceesfullyModal from "./SuceesfullyModal";
import TimePicker from "react-time-picker";
import { Context } from "../../../../utils/context";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import Spinner from "react-bootstrap/Spinner"; // Import Spinner

const AddNewComplaintModal = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(false); // Loader state

  const handleShowModal = (action) => {
    setAction(action);
    setModalShow(true);
  };

  const handleCloseModal = () => {
    setModalShow(false);
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const { getData, Select2Data, postData, IMG_URL, Select3Data } =
    useContext(Context);
  const [modalSuccess, setModalSuccess] = useState(false);

  const options = [
    { value: "Kirankumar", label: "Kirankumar" },
    { value: "Sanket", label: "Sanket" },
    { value: "Oshin", label: "Oshin" },
    { value: "Poonam", label: "Poonam" },
    { value: "Tejas", label: "Tejas" },
  ];

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    setValue,
  } = useForm();

  const onSubmit2 = async () => {
    try {
      const response = await postData(`/verify-otp/email-message`);
      if (response?.success) {
        await setModalSuccess(response?.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitotp = async (data) => {
    try {
      const response = await postData(`/verify-otp/mobile-otp-send`, {
        contact_no: getValues("customer_id.contact_no"),
      });

      if (response?.success) {
        await setModalSuccess(response.success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true); // Start loader
    try {
      let response;
      if (props.show === true) {
        response = await postData(`/complaint-management/complaints`, data);
      } else {
        response = await postData(
          `/complaint-management/complaints/${props.show}`,
          data
        );
      }
      if (response?.success) {
        // await onSubmit2();
        // await onSubmitotp();
      }
      reset();
      setTimeout(() => {
        setModalSuccess(0);
        props.onHide();
      }, 1000);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // End loader
    }
  };

  const [complaint_type, setComplaint_type] = useState([]);
  const [visit_type, setVisit_type] = useState([]);
  const [service_engineer, setservice_engineer] = useState([]);
  const [item_name, setitem_name] = useState([]);
  const [complaint_status, setcomplaint_status] = useState([]);
  const [customer, setCustomer] = useState([]);

  const [complaintpriorities, setcomplaintpriorities] = useState([]);
  const [handled_by, sethandled_by] = useState([]);
  const [complaint_source, setcomplaint_source] = useState([]);

  const [minScheduleDate, setMinScheduleDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  });

  const [scheduleDate, setScheduleDate] = useState("");

  const handleScheduleDateChange = (event) => {
    setScheduleDate(event.target?.value);
  };

  const [value, onChange] = useState("10:00");

  const [engineer, setEngineer] = useState("");
  const serviceEngineer = (e) => {
    setEngineer(e);
  };

  const getAllData = async () => {
    {
      if (props.show === true) {
        const res = await getData(`/masters/complaints-no`);
        reset(res?.data);
      } else {
        const res = await getData(
          `/complaint-management/complaints/${props.show}`
        );
        reset(res?.data);
      }
    }
    {
      const res = await getData(`/masters/all-complaint-type`);
      setComplaint_type(await Select2Data(res?.data, "complaint_type_id"));
    }
    {
      const res = await getData(`/masters/all-visit-type`);
      setVisit_type(await Select2Data(res?.data, "visit_id"));
    }
    {
      const res = await getData(`/masters/all-complaints-source`);
      setcomplaint_source(res?.data);
    }
    {
      const res = await getData(`/masters/all-service-engineers`);
      setservice_engineer(res?.data);
    }
    {
      const res = await getData(`/masters/allproducts-list`);
      setitem_name(await Select2Data(res?.data, "item_name_id"));
    }
    {
      const res = await getData(`/masters/all-complaint-status`);
      setcomplaint_status(await Select2Data(res?.data, "complaint_status_id"));
    }
    {
      const res = await getData(`/masters/all-complaint-priorities`);
      setcomplaintpriorities(
        await Select2Data(res?.data, "complaint_priorities_id")
      );
    }
    {
      const res = await getData(`/masters/all-customers`);
      setCustomer(await Select3Data(res?.data, "customer_id"));
    }
    {
      const res = await getData(`/masters/allusers`);
      const result = res?.data?.map((data) => ({
        value: data?.userid,
        label: data?.user?.name,
        name: "handled_by_id",
      }));
      sethandled_by(result);
    }
  };

  useEffect(() => {
    getAllData();
    reset();
  }, [props.show]);

  console.log(getValues("signature"), "vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv");

  return (
    <>
      <div>
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          className="main-class-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h6 className="mt-2 ms-2">
                {" "}
                {props.view
                  ? "View Complaint"
                  : props.show === true
                  ? "Add Complaint"
                  : "Edit Complaint"}
              </h6>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={() => handleSubmit(onSubmit)} role="form">
              <Row>
                {/* <Col xl={3}>
                  <Form.Label>Complaint Number</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    className={classNames("", {
                      "is-invalid": errors?.complaints_no,
                    })}
                    {...register("complaints_no", {
                      required: "Complaint number is required",
                    })}
                  />
                 
                  {errors.complaints_no && (
                    <span className="text-danger">
                      {errors.complaints_no.message}
                    </span>
                  )}
                </Col> */}

                <Col xl={3}>
                  <Form.Label>Complaint Number</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    readOnly
                    onKeyDown={(e) => {
                      if (props.view) {
                        e.preventDefault();
                      }
                    }}
                    className={classNames("", {
                      "is-invalid": errors?.complaints_no,
                    })}
                    {...register("complaints_no", {
                      required: "complaints_no is required",
                    })}
                  />
                </Col>

                {/* <Col xl={3}>
                  <Form.Label>Customer Name</Form.Label>
                  <Controller
                    name="customer_id" // name of the field
                    {...register("customer_id", {
                      required: "Customer is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        isDisabled={props.view}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.customer_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        onChange={(Option) => {
                          field.onChange(Option);
                          setValue("mobile_no_for_sms", Option);
                          console.log(Option);
                        }}
                        {...field}
                        options={customer}
                      />
                    )}
                  />
                  {/* <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                  /> 
                </Col> */}

                <Col xl={3}>
                  <Form.Label>Customer Name</Form.Label>
                  <Controller
                    name="customer_id" // name of the field
                    {...register("customer_id", {
                      required: "Customer is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        isDisabled={props.view}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.customer_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        onChange={(seletedOption) => {
                          field.onChange(seletedOption);
                          setValue(
                            "mobile_no_for_sms",
                            seletedOption.contact_no
                          );
                        }}
                        value={field.value}
                        options={customer}
                      />
                    )}
                  />
                  {/* <Form.Control required type="text" placeholder="" /> */}
                </Col>
                <Col xl={3}>
                  <Form.Label>Complaint Type</Form.Label>
                  <Controller
                    name="complaint_type_id" // name of the field
                    {...register("complaint_type_id", {
                      required: "complaint type is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        isDisabled={props.view}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.complaint_type_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={complaint_type}
                      />
                    )}
                  />
                  {/* <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                  /> */}
                </Col>
                <Col xl={3}>
                  <Form.Label>Visit Type</Form.Label>
                  <Controller
                    name="visit_type_id" // name of the field
                    {...register("visit_type_id", {
                      required: "visit type is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        isDisabled={props.view}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.visit_type_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={visit_type}
                      />
                    )}
                  />
                  {/* <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                  /> */}
                </Col>
              </Row>

              <Row className="mt-3">
                <Col xl={3}>
                  <Form.Label>Charges</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    disabled={props.view}
                    onInput={(e) => {
                      let value = parseInt(
                        e.target?.value.replace(/\D/g, ""),
                        10
                      );
                      if (value <= 0) {
                        value = "";
                      }
                      e.target.value = value;
                    }}
                    className={classNames("", {
                      "is-invalid": errors?.charges,
                    })}
                    {...register("charges")}
                  />
                </Col>

                <Col xl={3}>
                  <Form.Label>Bill</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    disabled={props.view}
                    onInput={(e) => {
                      let value = parseInt(
                        e.target.value.replace(/\D/g, ""),
                        10
                      );
                      if (value <= 0) {
                        value = "";
                      }
                      e.target.value = value;
                    }}
                    className={classNames("", {
                      "is-invalid": errors?.bill,
                    })}
                    {...register("bill")}
                  />
                </Col>

                <Col xl={3}>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    disabled={props.view}
                    required
                    type="text"
                    className={classNames("", {
                      "is-invalid": errors?.description,
                    })}
                    {...register("description")}
                  />
                </Col>
                {/* <Col xl={3}>
                  <Form.Label>Service Engineer</Form.Label>
                  <Controller
                    name="service_engineer_id" // name of the field
                    {...register("service_engineer_id", {
                      // required: "service engineer is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        isDisabled={props.view}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.service_engineer_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        onChange={(seletedOption) => {
                          field.onChange(seletedOption);
                          setValue(
                            "service_call_no",
                            seletedOption.mobile_number
                          );
                        }}
                        value={field.value}
                        options={service_engineer}
                      />
                    )}
                  />
                </Col> */}
              </Row>

              <Row className="mt-3">
                {/* <Col xl={3}>
                  <Form.Label>Reference no</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    disabled={props.view}
                    className={classNames("", {
                      "is-invalid": errors?.reference_no,
                    })}
                    {...register("reference_no")}
                  />
                </Col> */}
                <Col xl={3}>
                  <Form.Label>Call by whom</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    disabled={props.view}
                    className={classNames("", {
                      "is-invalid": errors?.call_by_whom,
                    })}
                    {...register(
                      "call_by_whom"
                      //  {
                      //   required: "Call by whom is required",
                      //   pattern: {
                      //     value: /^[^\d]+$/,
                      //     message: "Call by whom should not contain digits",
                      //   },
                      // }
                    )}
                  />
                </Col>

                <Col xl={3}>
                  <Form.Label>Schedule Date</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    min={minScheduleDate}
                    onChange={handleScheduleDateChange}
                    className={errors.schedule_date ? "is-invalid" : ""}
                    {...register("schedule_date", {
                      required: true,
                    })}
                  />
                  {errors.schedule_date && (
                    <Form.Control.Feedback type="invalid">
                      {errors.schedule_date.message}
                    </Form.Control.Feedback>
                  )}
                </Col>

                <Col xl={3}>
                  <Form.Label>Schedule Time</Form.Label>
                  <Form.Control
                    required
                    type="time"
                    className={errors.schedule_time ? "is-invalid" : ""}
                    {...register("schedule_time", {
                      required: true,
                    })}
                  />
                  {errors.schedule_time && (
                    <Form.Control.Feedback type="invalid">
                      {errors.schedule_time.message}
                    </Form.Control.Feedback>
                  )}
                </Col>
              </Row>

              <Row className="mt-3">
                <Col xl={3}>
                  <Form.Label>Item Name</Form.Label>
                  <Controller
                    name="item_name_id" // name of the field
                    {...register("item_name_id", {
                      required: "item name is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        isDisabled={props.view}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.item_name_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={item_name}
                      />
                    )}
                  />
                  {/* <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                  /> */}
                </Col>

                <Col xl={3}>
                  <Form.Label>Item Serial Number</Form.Label>
                  {/* <Form.Control required type="number" placeholder="" /> */}
                  <Form.Control
                    required
                    type="number"
                    disabled={props.view}
                    className={classNames("", {
                      "is-invalid": errors?.item_serial_no,
                    })}
                    {...register("item_serial_no")}
                  />
                </Col>
                <Col xl={3}>
                  <Form.Label>Status</Form.Label>
                  <Controller
                    name="complaint_status_id"
                    {...register("complaint_status_id", {
                      required: "Complaint Status is required",
                    })}
                    control={control}
                    rules={{ required: "Complaint status is required" }}
                    render={({ field }) => {
                      const isDisabled =
                        field.value?.value === 3 || field.value?.value === 4;

                      return (
                        <Select
                          {...field}
                          isDisabled={isDisabled}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.complaint_status_id
                                ? "red"
                                : baseStyles.borderColor,
                            }),
                          }}
                          options={
                            props.show === true
                              ? [{ value: "1", label: "New" }]
                              : complaint_status
                          }
                        />
                      );
                    }}
                  />
                </Col>

                <Col xl={3}>
                  <Form.Label>Source</Form.Label>
                  <Controller
                    name="complaint_source_id"
                    {...register("complaint_source_id")}
                    control={control}
                    render={({ field }) => (
                      <Select
                        isDisabled={props.view}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.complaint_source_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={complaint_source}
                      />
                    )}
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col xl={3}>
                  <Form.Label>Service Call Number</Form.Label>
                  <Form.Control
                    required
                    type="tel"
                    disabled={props.view}
                    minLength={10}
                    pattern="^\+?\d{10,}$"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/\D/g, "");

                      if (e.target.value.length > 10) {
                        e.target.value = e.target.value.slice(0, 10);
                      }
                    }}
                    className={classNames("", {
                      "is-invalid": errors?.service_call_no,
                    })}
                    {...register("service_call_no", {
                      required: "Service call Number is required",
                      // getValues(service_engineer.co_number),
                      pattern: {
                        value: /^\+?\d{10,}$/,
                        message:
                          "Please enter a valid mobile number with 10 digits.",
                      },
                    })}
                  />
                </Col>

                {/* <Col xl={3}>
                  <Form.Label>Completion Date</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    min={minScheduleDate}
                    className={errors.completion_time ? "is-invalid" : ""}
                    {...register("completion_time", {
                      required: true,
                      validate: {
                        greaterThanSchedule: (value) => {
                          if (
                            getValues("completion_time") <
                            getValues("schedule_date")
                          ) {
                            return false;
                            // return "Completion date should be greater than or equal to the schedule date";
                          }
                          return true;
                        },
                      },
                    })}
                  />
                  {errors.completion_time && (
                    <Form.Control.Feedback type="invalid">
                      {errors.completion_time.message}
                    </Form.Control.Feedback>
                  )}
                </Col> */}

                <Col xl={3}>
                  <Form.Label>Handled By</Form.Label>
                  <Controller
                    name="handled_by_id" // name of the field
                    {...register("handled_by_id")}
                    control={control}
                    render={({ field }) => (
                      <Select
                        isDisabled={props.view}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.handled_by_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={handled_by}
                      />
                    )}
                  />
                </Col>
                <Col xl={3}>
                  <Form.Label>Mobile Number for SMS</Form.Label>
                  <Form.Control
                    required
                    type="tel"
                    disabled={props.view}
                    minLength={10}
                    pattern="^\+?\d{10,}$"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/\D/g, "");

                      if (e.target.value.length > 10) {
                        e.target.value = e.target.value.slice(0, 10);
                      }
                    }}
                    className={classNames("", {
                      "is-invalid": errors?.mobile_no_for_sms,
                    })}
                    {...register("mobile_no_for_sms", {
                      required: "Mobile number for SMS is required",
                      pattern: {
                        value: /^\+?\d{10,}$/,
                        message:
                          "Please enter a valid mobile number with 10 digits.",
                      },
                    })}
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col xl={3}>
                  <Form.Label>Remark</Form.Label>
                  {/* <Form.Control required type="text" placeholder="" /> */}
                  <Form.Control
                    required
                    type="text"
                    disabled={props.view}
                    className={classNames("", {
                      "is-invalid": errors?.remark,
                    })}
                    {...register("remark")}
                  />
                </Col>
                {/* <Col xl={3}>
                  <Form.Label>History</Form.Label>
                  {/* <Form.Control required type="text" placeholder="" />
                  <Form.Control
                    required
                    type="text"
                    disabled={props.view}
                    className={classNames("", {
                      "is-invalid": errors?.history,
                    })}
                    {...register("history", {
                      required: "history is required",
                    })}
                  />
                </Col> */}
                {/* <Col xl={3}>
                  <Form.Label>History</Form.Label>
                  <Controller
                    name="complaint_status_id" // name of the field
                    {...register("complaint_status_id", {
                      required: "complaint status is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        isDisabled={props.view}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.complaint_status_id
                              ? "red"
                              : baseStyles.borderColor,
                          }),
                        }}
                        {...field}
                        value={props.show === true["disable"]}
                      />
                    )}
                  />
                </Col> */}
                <Col xl={3}>
                  <Form.Label>Case Priority</Form.Label>
                  {/* <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                  /> */}
                  <Controller
                    name="complaint_priorities_id" // name of the field
                    {...register("complaint_priorities_id", {
                      required: "complaint priorities is required",
                    })}
                    control={control}
                    render={({ field }) => (
                      <Select
                        isDisabled={props.view}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.complaint_priorities_id
                              ? "red"
                              : baseStyles,
                          }),
                        }}
                        {...field}
                        options={complaintpriorities}
                      />
                    )}
                  />
                </Col>{" "}
                <Col xl={3}>
                  {getValues("signature") == null ? (
                    ""
                  ) : (
                    <>
                      <Form.Label>Customer Signature</Form.Label>
                      <img
                        className="signature"
                        src={IMG_URL + getValues("signature")}
                      />
                    </>
                  )}
                </Col>
              </Row>
              <div className="text-center mt-5">
                {props.view === true ? (
                  <></>
                ) : (
                  <>
                    <Button className="cancel-btn me-2" onClick={props.onHide}>
                      Cancel
                    </Button>
                    <Button
                      className="add-btn"
                      type="button"
                      onClick={handleSubmit(onSubmit)}
                      disabled={loading} // Disable button when loading
                    >
                      {
                        loading ? ( // Show spinner when loading
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : props.show === true ? (
                          "Add"
                        ) : (
                          "Edit"
                        ) // Show button text
                      }
                    </Button>
                  </>
                )}
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
      <SuceesfullyModal
        show={modalSuccess}
        onHide={() => setModalSuccess(false)}
      />
    </>
  );
};

export default AddNewComplaintModal;
